import React from 'react'
import { Link } from 'gatsby'

import arrowRightIcon from '../icons/arrow-right.svg'
import downloadIcon from '../icons/download.svg' // change with right one

const LinkIcon = ({text, link, className, color, iconColor, external, noFollow, mailto, tel, contentType = false, isDownload = false, small = false }) => {
  let button

  color = (color) ? color : 'night'
  iconColor = (iconColor) ? iconColor : 'night'
  const icon = isDownload ? downloadIcon.url : arrowRightIcon.url

  if (link) {
    if (external) {
      button = <ExternalLink small={small} text={text} className={className} color={color} iconColor={iconColor} isDownload={isDownload} link={link} noFollow={noFollow} icon={icon} mailto={mailto} tel={tel} />
    } else {
      button = <InternalLink small={small} text={text} className={className} color={color} iconColor={iconColor} isDownload={isDownload} link={link} icon={icon} contentType={contentType} />
    }
  } else {
    button = <NoLink small={small} text={text} className={className} isDownload={isDownload} color={color} iconColor={iconColor} icon={icon} />
  }

  return (
    <>
    {button}
    </>
  )
}

const InnerContent = ({text, icon, iconColor, small}) => {
  return (
    <>
      { !small &&
        <span className={`center link-icon__icon bg-${iconColor}`}>
          <svg className="icon-white" width="32" height="32">
            <use xlinkHref={ icon } />
          </svg>
        </span>
      }
      <span><span className={!small ? 'hover-underline' : ''} dangerouslySetInnerHTML={{__html: text}}></span></span>
    </>
  )
}

const InternalLink = ({text, link, className, color, icon, iconColor, isDownload, small}) => {
  return (
    <>
      <Link to={link} className={`link-icon ${isDownload ? 'link-icon--download' : '' } text-${color} ${className ? className : ''}`}>
        <InnerContent text={text} color={color} iconColor={iconColor} icon={icon} small={small} />
      </Link>
    </>
  )
}

const ExternalLink = ({text, link, className, color, noFollow, icon, mailto, tel, iconColor, isDownload, small}) => {
  let rel
  let target = '_blank' 

  if (mailto) {
    link = `mailto:${link}`
    target = ''
  }

  if (tel) {
    link = `tel:${link}`
    target = ''
  }

  if (noFollow) {
    rel = "noopener noreferrer nofollow"
  } else {
    rel = "noopener noreferrer"
  }

  return (
    <a href={link} target={target} rel={rel} className={`link-icon ${isDownload ? 'link-icon--download' : '' } text-${color} ${className ? className : ''}`}>
      <InnerContent text={text} color={color} iconColor={iconColor} icon={icon} small={small} />
    </a>
  )
}

const NoLink = ({text, className, color, icon, iconColor, isDownload, small}) => {
  return (
    <span className={`link-icon ${isDownload ? 'link-icon--download' : '' } text-${color} ${className ? className : ''}`}>
      <InnerContent text={text} color={color} iconColor={iconColor} icon={icon} small={small} />
    </span>
  )
} 

export default LinkIcon