import slugify from 'slugify'
import GetNewsroomUrls from './getNewsroomUrls'

const previewContentType = (type) => {
  return `Contentful${type.charAt(0).toUpperCase() + type.slice(1)}`
}

const transformLink = (linkItem, linkPage = true, fromAlgolia = false) => {
  let url = null
  let mailto = false
  let tel = false
  let external = false
  let contentTypeContentful = null
  const re = new RegExp('^(http|https)://', 'i')
  const isMail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
  const isPhone = /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/
  
  if (linkItem !== null && linkItem !== undefined) {
    if((linkPage && linkItem.pageLink !== null && linkItem.pageLink !== undefined) || !linkPage) {
      let pageLink
      let locale
      let contentType = null
      let title, slug
      
      if (!linkPage) {
        pageLink = linkItem
        locale = (linkItem.sys) ? linkItem.sys.locale : linkItem.node_locale
        title = (linkItem.fields) ? linkItem.fields.title : linkItem.title
        slug = (linkItem.fields) ? linkItem.fields.slug : linkItem.slug
      } else {
        pageLink = (linkItem.pageLink.fields) ? linkItem.pageLink.fields : linkItem.pageLink
        locale = (linkItem.pageLink.fields) ? linkItem.pageLink.sys.locale : linkItem.pageLink.node_locale
        title = pageLink.title
        slug = pageLink.slug
      }

      if(pageLink.internal) {
        contentType = pageLink.internal.type
      } else {
        if (linkPage) {
          contentType = previewContentType(linkItem.pageLink.sys.contentType.sys.id)
        } else {
          if(fromAlgolia) {
            contentType =  previewContentType(linkItem.contentType)
          } else {
            contentType = previewContentType(linkItem.sys.contentType.sys.id)
          }
        }
      }

      contentTypeContentful = contentType

      const newsroomListUrls = ['ContentfulEvent', 'ContentfulNews']
      const slugUrls = ['ContentfulSimulator', 'ContentfulPage', 'ContentfulContact', 'ContentfulHub', 'ContentfulNewsroomList', 'ContentfulLanding']
      let newsroomUrls = null

      if (slugUrls.indexOf(contentType) !== -1) {
        if(contentType !== 'ContentfulPage') {
          contentType = 'slugPages'
        } else {
          if(pageLink.customType === 'Médiathèque') {
            contentType = 'medias'
            newsroomUrls = GetNewsroomUrls()
          } else if(pageLink.customType === 'Blog') {
            contentType = 'blog'
            newsroomUrls = GetNewsroomUrls()
          } else {
            contentType = 'slugPages'
          }
        }
      } else {
        if(newsroomListUrls.indexOf(contentType) !== -1) {
          newsroomUrls = GetNewsroomUrls()
        }
      }

      switch(contentType) {
        case 'ContentfulNews':
          if(linkPage && locale === undefined) {
            locale = (linkItem.pageLink.fields) ? linkItem.pageLink.sys.locale : linkItem.pageLink.node_locale
          }
          
          url = `/${newsroomUrls['Actualités'][locale]}/${slugify(title, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
          break
        case 'ContentfulEvent':
          url = `/${newsroomUrls['Événements'][locale]}/${slugify(title, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
          break
        case 'ContentfulSolution':
            url = `${locale === 'fr' ? '/' : '/en/'}solutions/${slugify(title, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
            break
        case 'ContentfulOffer':
          let solution 

          if (linkPage) {
            solution = linkItem.fields ? linkItem.fields.solution.fields.pageLink.title : linkItem.pageLink.fields ? linkItem.pageLink.fields.solution.fields.title  : linkItem.pageLink.solution.title
          } else {
            solution = linkItem.fields ? linkItem.fields.solution.fields.title : linkItem.solution.title
          }
          url = `${locale === 'fr' ? '/' : '/en/'}solutions/${slugify(solution, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}/${slugify(title, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
          break
        case 'medias':
          url = `/${newsroomUrls['Médiathèque'][locale]}/${slugify(title, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
          break
        case 'blog':
          url = `/${newsroomUrls['Blog'][locale]}/${slugify(title, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
          break
        case 'slugPages':
          url = `${locale === 'fr' ? '/' : '/en/'}${slug}`
          break
        case 'ContentfulHomepage':
          url = `${locale === 'fr' ? '/' : '/en'}`
          break
        default:
          url = null
      }
    } else {
      //asset link
      if (linkItem.assetLink) {
        let assetLink = (linkItem.assetLink.fields) ? linkItem.assetLink.fields : linkItem.assetLink
        url = (assetLink.file) ? assetLink.file.url : null
        external = true
      // custom link
      } else {  
        url = linkItem.customLink

        if (url.match(re)) {
          external = true
        } else if (isMail.test(url)) {
          mailto = true
          external = true
        } else if (isPhone.test(url)) {
          tel = true
          external = true
        }
      }
    }
  }

  return { url, external, mailto, tel, contentTypeContentful }
}

export default transformLink