import React from 'react'
import { Link } from 'gatsby'
import slugify from 'slugify'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useSelector } from 'react-redux'

import VideoPlayer from './slices/videoPlayer'
import Testimony from './slices/testimony'
import GetNewsroomUrls from '../utils/getNewsroomUrls'
import CookiesSettings from './cookiesSettings'
import Table from './slices/table'

const Wysiwyg = ({classes, document, noClass = false, isOffer = false, hasTitle = false}) => {
  const lang = useSelector(state => state.lang)
  const newsroomUrls = GetNewsroomUrls()

  const renderEmbed = (type, video, url, alt, testimony, isOffer) => {
    switch(type) {
      case 'ContentfulFrontImg':
        return <div className={`slice-image wrap-wysiwyg`}><img className="lazyload block mx-auto" src={url} alt={alt} /></div>
      case 'ContentfulVideo':
        return <VideoPlayer data={video} isWysiwyg={true} sliceHasTitle={hasTitle} isOffer={isOffer} />
      case 'ContentfulTestimony':
        return (
          <Testimony data={testimony} isWysiwyg={true} />
        )
      default:
        return 'error'
    }
  }
  
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        let type = node.data.target['__typename'] ? node.data.target['__typename'] : node.data.target.sys.contentType.sys.id
        let image, alt, url
        let video = {}
        let testimony = {}

        if (!type.includes('Contentful')) {
          type = `Contentful${type.charAt(0).toUpperCase() + type.slice(1).replace('_img', 'Img')}`
        }

        if (type === 'ContentfulFrontImg') {
          image = node.data.target.fields ? node.data.target.fields : node.data.target

          url = image.file.fields ? image.file.fields.file.url : image.file.url
          alt = image.altImage
        } else if(type === 'ContentfulVideo') {
          video.title = (node.data.target.fields) ? node.data.target.fields.title : node.data.target.title
          video.cover = (node.data.target.fields) ? node.data.target.fields.cover : node.data.target.cover
          video.youtubeId = (node.data.target.fields) ? node.data.target.fields.youtubeId : node.data.target.youtubeId

          if ((node.data.target.fields && node.data.target.fields.youtubePlaylistId) || (node.data.target.youtubePlaylistId) ) {
            video.youtubePlaylistId = (node.data.target.fields) ? node.data.target.fields.youtubePlaylistId : node.data.target.youtubePlaylistId
          }    
        } else {
          testimony.author = (node.data.target.fields) ? node.data.target.fields.author : node.data.target.author
          testimony.theme = (node.data.target.fields) ? node.data.target.fields.theme : node.data.target.theme
          testimony.content = (node.data.target.fields) ? node.data.target.fields.content : node.data.target.content
          testimony.job = (node.data.target.fields) ? node.data.target.fields.job : node.data.target.job
          testimony.image = (node.data.target.fields) ? node.data.target.fields.image.fields : node.data.target.image
          
          testimony.image.file = (testimony.image.file.fields) ? testimony.image.file.fields.file : testimony.image.file
        }

        return (
          <>
            {renderEmbed(type, video, url, alt, testimony, isOffer)}
          </>
        )
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const el =  node.data.target

        const text = (el.fields) ? el.fields.text.fr : el.text
        const type = (el.fields) ? el.fields.type.fr : el.type
        
        return (
          <>
            { type === 'Indice' ?
              <sub>{text}</sub>
            : <sup>{text}</sup>
            }
          </>
        )
      },
      [BLOCKS.TABLE]: (node) => {
        return <Table datas={node} />
      },
      [INLINES.ASSET_HYPERLINK]: (node) => {
        const text = node.content[0].value
        const file = node.data.target.fields ? node.data.target.fields.file : node.data.target.file
        
        const url = (file.fr) ? file[lang].url : file.url 

        return <a href={url} target="_blank" rel="noopener noreferrer">{text}</a>
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        let contentType = node.data.target.internal ? node.data.target.internal.type : node.data.target.sys.contentType.sys.id
        const text = node.content[0].value
        const slugUrls = ['ContentfulPage', 'ContentfulContact', 'ContentfulHub', 'ContentfulNewsroomList', 'ContentfulSimulator', 'ContentfulLanding']
        let url = null

        if (!contentType.includes('Contentful')) {
          contentType = `Contentful${contentType.charAt(0).toUpperCase() + contentType.slice(1)}`
        }

        if (slugUrls.indexOf(contentType) !== -1) {
          if(contentType !== 'ContentfulPage') {
            contentType = 'slugPages'
          } else {
            let customType = (typeof node.data.target.customType === 'object') ? node.data.target.customType[lang] : node.data.target.customType
            
            if(customType === 'Médiathèque') {
              contentType = 'medias'
            } else if(customType === 'Blog') {
              contentType = 'blog'
            } else {
              contentType = 'slugPages'
            }
          }
        }

        switch(contentType) {
          case 'ContentfulNews':
            let titleNews = node.data.target.fields ? node.data.target.fields.title : node.data.target.title
            url = `/${newsroomUrls['Actualités'][lang]}/${slugify(titleNews, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
            break
          case 'blog':
            let titleBlog = node.data.target.fields ? node.data.target.fields.title : node.data.target.title
            url = `/${newsroomUrls['Blog'][lang]}/${slugify(titleBlog, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
            break
          case 'medias':
            let titleMedias = node.data.target.fields ? node.data.target.fields.title : node.data.target.title
            url = `/${newsroomUrls['Médiathèque'][lang]}/${slugify(titleMedias, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
            break
          case 'ContentfulEvent':
            let titleEvent = node.data.target.fields ? node.data.target.fields.title : node.data.target.title
            url = `/${newsroomUrls['Événements'][lang]}/${slugify(titleEvent, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
            break
          case 'ContentfulSolution':
            let titleSolution = node.data.target.fields ? node.data.target.fields.title : node.data.target.title
            url = `${lang === 'fr' ? '/' : '/en/'}solutions/${slugify(titleSolution, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
            break
          case 'ContentfulOffer':
            let titleOffer = node.data.target.fields ? node.data.target.fields.title : node.data.target.title
            let titleOfferSolution = node.data.target.fields ? node.data.target.fields.solution.fields.title : node.data.target.solution.title

            url = `${lang === 'fr' ? '/' : '/en/'}solutions/${slugify(titleOfferSolution, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}/${slugify(titleOffer, {remove: /[*+~.,()'"!?:@,]/g}).toLowerCase()}`
            break
          case 'slugPages':
            let slug = (node.data.target.fields) ? node.data.target.fields.slug : node.data.target.slug
            slug = (typeof slug === 'object') ? node.data.target.slug[lang] : slug
            url = `${lang === 'fr' ? '/' : '/en/'}${slug}`
            break
          case 'ContentfulHomepage':
            url = `${lang === 'fr' ? '/' : '/en'}`
            break
          default:
            url = null
        }

        return (
          <>
            { contentType === 'ContentfulSimulator' || contentType === 'ContentfulContact' ?
              <a href={url}>{text}</a>
            :  <Link to={url}>{text}</Link>
            }
          </>
        )
      },
      [INLINES.HYPERLINK]: (node) => {
        const url = node.data.uri
        const text = node.content[0].value
        const re = new RegExp('^(http|https)://', 'i')
        const mail = new RegExp('^mailto:', 'i')
        const tel = new RegExp('tel:', 'i')

        if (url.match(re)) {
          return <a href={url} target="_blank" rel="noopener noreferrer">{text}</a>
        } else if(url.match(mail) || url.match(tel)) {
          return <a href={url}>{text}</a>
        } else if(url === 'cookies') {
          return <CookiesSettings text={text} className="show-cookies-settings" />
        } else {
          return <Link to={url}>{text}</Link>
        }
      }
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br key={i} />, text])
  }

  classes = classes ? classes : ''
  const wrapperClass =  noClass ? classes : `paragraph ${classes}`

  return (
    <div className={wrapperClass}>
      { document && 
        document.raw ? renderRichText(document, options) : documentToReactComponents(document, options) 
      }
    </div>
  )
}

export default Wysiwyg