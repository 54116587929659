const eventDate = (startDate, endDate, from, to, the, lang) => {
  startDate = new Date(startDate)
  const yearStartDate = new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(startDate)
  const monthStartDate = new Intl.DateTimeFormat(lang, { month: 'long' }).format(startDate)
  const dayStartDate = new Intl.DateTimeFormat(lang, { day: 'numeric' }).format(startDate)

  let startDateString = (lang === 'fr') ? `${dayStartDate} ${monthStartDate} ${yearStartDate}` : `${monthStartDate} ${dayStartDate}, ${yearStartDate}`
  let endDateString

  if (endDate) {
    endDate = new Date(endDate)
    const yearEndDate = new Intl.DateTimeFormat(lang, { year: 'numeric' }).format(endDate)
    const monthEndDate = new Intl.DateTimeFormat(lang, { month: 'long' }).format(endDate)
    const dayEndDate = new Intl.DateTimeFormat(lang, { day: 'numeric' }).format(endDate)

    endDateString = (lang === 'fr') ? `${dayEndDate} ${monthEndDate} ${yearEndDate}` : `${monthEndDate} ${dayEndDate}, ${yearEndDate}`

    if (yearStartDate === yearEndDate && monthStartDate === monthEndDate) {
      startDateString = dayStartDate
      endDateString = (lang === 'fr') ? `${dayEndDate} ${monthEndDate} ${yearEndDate}` : `${dayEndDate} ${monthEndDate}, ${yearEndDate}`
    } else if (yearStartDate === yearEndDate) {
      startDateString = lang === 'fr' ? `${dayStartDate} ${monthStartDate}` : `${monthStartDate} ${dayStartDate}`
    }
  }

  const date = (endDate) ? `${from} ${startDateString} ${to} ${endDateString}` : `${the} ${startDateString}`
  
  return date
}

export default eventDate