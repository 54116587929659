import React, { useState, useEffect} from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import Helmet from 'react-helmet'
import YouTube from 'react-youtube'
import Slider from '../slider'
import trads from '../../intl/trads.json'

import 'element-closest-polyfill'

import play from '../../icons/play.svg'
import hexagonShape from '../../icons/hexagon-shape.svg'
import semiroundShape from '../../icons/semiround-shape.svg'
import roundShape from '../../icons/round-shape.svg'

const VideoPlayer = ({data, isWysiwyg = false, isOffer = false, sliceHasTitle = false}) => {
  const { ref, inView} = useInView({ threshold: 0.3, triggerOnce: true})
  const lang = useSelector(state => state.lang)
  // local state
  const [player, setPlayer] = useState(null)
  const [currentVideoPlaylist, setCurrentVideoPlaylist] = useState(0)
  const [thumbnailsVideoPlaylist, setThumbnailsVideoPlaylist] = useState(null)
  const [featuredVideo, setFeaturedVideo] = useState(data.youtubeId)
  const [featuredVideoPos, setFeaturedVideoPos] = useState(0)
  const [isInit, setIsInit] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [divStyle, setDivStyle] = useState({ backgroundImage: 'url("")'})

  const cover = data.cover
  let bgUrl = cover !== undefined ? cover.fields ? cover.fields.file.url : cover.file.url : null

  // case from WYSIWYG
  if(bgUrl === undefined) {
    bgUrl = cover.fields.file[lang].url
  }

  const [helmetDatas, setHelmetDatas] = useState(null)

  const size = 'col-12 lg-col-10 mx-auto'

  const handlePlayerPlay = () => {
    setIsInit(false)
    setIsActive(true)

    if (data.youtubePlaylistId) {
      player.loadPlaylist({
        list:  data.youtubePlaylistId,
        listType: 'playlist',
        index: featuredVideoPos
      })
    } else {
      player.playVideo()
    }
  }

  const handleThumbnailsPlay = (index) => {
    setIsInit(false)
    setIsActive(true)

    player.loadPlaylist({
      list: data.youtubePlaylistId,
      listType: 'playlist',
      index: index
    })
  }

  const opts = {
    playerVars: {
      rel: 0
    }
  }

  useEffect(() => {
    setDivStyle({ backgroundImage: `url(${bgUrl})`})
    
    if (data.youtubePlaylistId) {
      Axios.get(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2C+id&maxResults=50&playlistId=${data.youtubePlaylistId}&key=${process.env.GATSBY_YOUTUBE_API_KEY}`)
        .then(response => {
          let thumbnailsVideoPlaylistTemp = []

          // get url param to change featured video
          const urlParams = new URLSearchParams(window.location.search)
          const pos = urlParams.get('video')  !== null ? parseInt(urlParams.get('video')) : 0

          setFeaturedVideoPos(pos < response.data.items.length ? pos : 0)
          setCurrentVideoPlaylist(pos < response.data.items.length ? pos : 0)

          response.data.items.forEach((item, i) => {
            if (i === featuredVideoPos) {
              setFeaturedVideo(item.snippet.resourceId.videoId)
            }

            thumbnailsVideoPlaylistTemp.push({
              'image': item.snippet.thumbnails.maxres ? item.snippet.thumbnails.maxres : item.snippet.thumbnails.default,
              'title': item.snippet.title
            })
          })

          setThumbnailsVideoPlaylist(thumbnailsVideoPlaylistTemp)
        })
        .catch(error => {
          console.log(error)
        })
    }

    // get datas for helmet
    Axios.get(`https://www.googleapis.com/youtube/v3/videos?id=${data.youtubeId}&key=${process.env.GATSBY_YOUTUBE_API_KEY}&part=snippet,contentDetails`)
      .then(response => {
        const datas = response.data.items[0]

        setHelmetDatas(`{
          "@context": "http://schema.org",
          "@type": "VideoObject",
          "name": "${datas.snippet.title}",
          "description": "${datas.snippet.description === '' ? 'Pas de description' : datas.snippet.description}",
          "thumbnailUrl": "https://i.ytimg.com/vi/${data.youtubeId}/default.jpg",
          "uploadDate": "${datas.snippet.publishedAt}",
          "duration": "${datas.contentDetails.duration}",
          "embedUrl": "https://www.youtube.com/embed/${data.youtubeId}"
        }`)
      })
      .catch(error => {
        // handle error
        console.log(error);
      })
    
  }, [data.youtubeId, data.youtubePlaylistId, helmetDatas, featuredVideoPos, bgUrl])

  const _onReady = (event) => {
    setPlayer(event.target)
  }

  const _onStateChange = (event) => {
    if (player != null && data.youtubePlaylistId && !isInit) {
      setCurrentVideoPlaylist(player.getPlaylistIndex())
    }
  }
  
  const _onEnd = (event) => {
    setIsActive(false)
    setCurrentVideoPlaylist(0)
  }

  return (
    <div ref={ref} className={`row slice-video ${inView ? 'has-anim' : ''}`}>
      <div className={`scroll-anim-el ${isWysiwyg ? `col-12 ${isOffer ? 'lg-col-9' : 'lg-col-10'} wrap-wysiwyg` : size}`}>
        <div className={`video full-width-tablet ${isActive ? 'is-active' : ''}`} style={ divStyle }>
          <div className="video__player">
            <YouTube
              videoId={featuredVideo}
              onReady={_onReady}
              onEnd={_onEnd}
              onStateChange={_onStateChange}
              opts={opts}
            />
          </div>
          
          <button className="row video__content" onClick={handlePlayerPlay}>
            <div className={isWysiwyg ? 'w-full xl:px-20' : 'col-12 lg-col-8 mx-auto'}>
              
              <div className="video__wrapper">
                <span className="video__button">
                  <svg className="absolute video__play" width="48" height="48" >
                    <use xlinkHref={play.url} />
                  </svg>
                  <svg width="100" height="100" className="absolute icon-night video__hexagon">
                    <use xlinkHref={hexagonShape.url} />
                  </svg>
                  <svg width="25" height="35" className="absolute icon-turquoise video__semiround">
                    <use xlinkHref={semiroundShape.url} />
                  </svg>
                  <svg width="24" height="24" className="absolute icon-aqua video__round">
                    <use xlinkHref={roundShape.url} />
                  </svg>
                </span>

                <div>
                  { data.suptitle &&
                    <p className="text-label text-label--big text-white mb-2 md:mb-4">{data.suptitle}</p>
                  }
                  { sliceHasTitle ?
                    <h3 className="video__title">{featuredVideoPos === 0 ? data.title : thumbnailsVideoPlaylist && thumbnailsVideoPlaylist[featuredVideoPos] ? thumbnailsVideoPlaylist[featuredVideoPos].title : data.title}</h3>
                  : <h2 className="video__title">{featuredVideoPos === 0 ? data.title : thumbnailsVideoPlaylist && thumbnailsVideoPlaylist[featuredVideoPos] ? thumbnailsVideoPlaylist[featuredVideoPos].title : data.title}</h2>
                  }
                </div>
              </div>
            </div>
          </button>
        </div>
        
        { data.youtubePlaylistId &&
          <div className="video__thumbnails">
            <p className="title title-small mt-6 mb-4">{ trads[lang].otherVideos }</p>
            
            <div className="relative">
              { thumbnailsVideoPlaylist != null &&
                <Slider
                  navCentered={true}
                  name="thumbnails"
                  nbItems={thumbnailsVideoPlaylist.length}
                  options='{"slidesPerView": 2, "spaceBetween": 24,"breakpoints": { "768": { "slidesPerView": 3}, "1280": { "slidesPerView": 3 }} }'
                >
                  { thumbnailsVideoPlaylist.map((thumbnail, i) => {
                      const buttonStyle = {
                        backgroundImage: `url(${thumbnail.image.url})`,
                      }

                      return (
                        <button key={i} className={`video__thumbnail ${currentVideoPlaylist === i ? 'video__thumbnail--playing' : ''}`} onClick={() => handleThumbnailsPlay(i)}>
                          <div className="video__thumbnail-img has-overlay" style={ buttonStyle }>
                            <svg className="icon" width="28">
                              <use xlinkHref={`#${play.id}`} />
                            </svg>
                          </div>
                          <p className="video__thumbnail-title mt-2">{ thumbnail.title }</p>
                        </button>
                      )
                  }) }
                </Slider>
              }
            </div>
          </div>
        }
      </div>

      { helmetDatas &&
        <Helmet>
          <script type="application/ld+raw">{helmetDatas}</script>
        </Helmet>
      }
    </div>
  )
}

export default VideoPlayer
