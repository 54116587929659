import React from 'react'
import { useSelector } from 'react-redux'
import { connectSearchBox } from 'react-instantsearch-dom'

import trads from '../../intl/trads.json'

import searchIcon from '../../icons/search.svg'

const Searchbox = ({ currentRefinement, refine }) => {
    const lang = useSelector(state => state.lang)

    return (
        <form noValidate action="" role="search">
            <div className="row items-center">
                <div className="col-12 md-col-6 lg-col-5 xl-col-4">
                    <label htmlFor="laboInput" className="search-panel__intro mb-4 md:mb-0 block">{ trads[lang].search.inputLabel1 }<br/>{ trads[lang].search.inputLabel2 }</label>
                </div>

                <div className="col-12 md-col-6 lg-col-7 xl-col-8">
                    <div className="relative">
                        <input 
                            id="laboInput"
                            className="search-panel__input"
                            type="search"
                            onChange={event => refine(event.currentTarget.value)}
                            placeholder={ trads[lang].search.inputPlaceholder } />
                        <button className="search-panel__submit" onClick={event => event.preventDefault()}>
                            <span className="sr-only">Search</span>
                            <svg className="icon-night" width="36" height="36">
                            <use xlinkHref={searchIcon.url} />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

const CustomSearchbox = connectSearchBox(Searchbox)

export default CustomSearchbox
