import React, { useEffect, useState, useCallback } from 'react'
import SwiperCore, { Navigation, A11y, EffectFade, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useEventListener } from 'react-recipes'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'

import arrowSlider from '../icons/arrow-slider.svg'

/* eslint-disable import/first */
SwiperCore.use([Navigation, A11y, EffectFade, Pagination])

const Slider = ({ options, children, name, hasCards = false, className = null, hasPagination = false, navCentered = false, nbItems = 0, paginationClass = false }) => {
  options = JSON.parse(options)

  const [hasNav, setHasNav] = useState(false)

  const randomNumber = Math.floor(Math.random() * 150)

  let sliderParams = {
    navigation: {
      prevEl: `.${name}-${randomNumber}-previous`,
      nextEl: `.${name}-${randomNumber}-next`,
      disabledClass: 'is-disabled',
    },
    ...options
  }

  if (hasPagination) {
    sliderParams = {
      ...sliderParams,
      pagination: {
        el: `.${name}-${randomNumber}-pagination`,
        clickable: true
      }
    }
  }

  const checkIfHasNav = () => {
    const windowWidth = window.innerWidth
    let tempHasNav = true

    if (nbItems === 1) {
      tempHasNav = false
    }

    if (options.breakpoints) {
      Object.entries(options.breakpoints).forEach((item, key) => {
        if (windowWidth >= parseInt(item[0])) {
          if (nbItems <= parseInt(item[1].slidesPerView)) {
            tempHasNav = false
          } else {
            tempHasNav = true
          }
        }
      })
    }

    setHasNav(tempHasNav)
  }

  useEffect(() => {
    checkIfHasNav()
  })

  const handleResize = useCallback(() => {
    checkIfHasNav()
  /* eslint-disable-next-line */
  }, [])

  // resize event listener
  useEventListener('resize', handleResize)

  return (
    <>
      <div className={`slider-controls ${!hasNav ? 'is-disabled' : ''} ${navCentered ? 'slider-controls--centered' : ''}`}>
        <button className={`slider-controls__button center slider-controls__button--left ${name}-${randomNumber}-previous`}>
          <svg className="icon-night" width="32" height="32">
            <use xlinkHref={arrowSlider.url} />
          </svg>
        </button>
        <button className={`slider-controls__button center slider-controls__button--right ${name}-${randomNumber}-next`}>
          <svg className="icon-night" width="32" height="32">
            <use xlinkHref={arrowSlider.url} />
          </svg>
        </button>
      </div>

      <div className={ hasCards ? 'slider-cards__inner' : className ? className : ''}>
        <Swiper
            {...sliderParams}
        >
          {children.map((child,i) => {
            return (
              <SwiperSlide key={i}>{child}</SwiperSlide>
            )
          })}
        </Swiper>

        { hasPagination && name !== 'medias' &&
          <div className={`${paginationClass ? paginationClass : ''} ${name}-${randomNumber}-pagination swiper-pagination ${navCentered ? 'swiper-pagination--centered' : ''}`}></div>
        }
      </div>

      { hasPagination && name === 'medias' &&
        <div className={`${name}-${randomNumber}-pagination swiper-pagination ${navCentered ? 'swiper-pagination--centered' : ''}`}></div>
      }
    </>
  )
}

export default Slider