import React from 'react'

import Image from './image'

import semiroundShape from '../icons/semiround-shape.svg'
import hexagonShape from '../icons/hexagon-shape.svg'
import roundShape from '../icons/round-shape.svg'
import quadShape from '../icons/quad-shape.svg'

const ShapeImageIllu = ({image, theme, shape, isReversed = false}) => {
  let roundColor, quadColor, semiround1Color, semiround2Color, hexagonColor
  let width, height, sizes

  switch (theme) {
    case 'gradient-blue':
      roundColor = 'aqua'
      quadColor = 'purple'
      semiround1Color = 'purple'
      semiround2Color = 'night'
      hexagonColor = 'night'
      break
    case 'gradient-sienne':
      roundColor = 'beige'
      quadColor = 'sienne'
      semiround1Color = 'sienne'
      semiround2Color = 'night'
      hexagonColor = 'night'
      break
    case 'blue':
      roundColor = 'night'
      quadColor = 'gradient-blue'
      semiround1Color = 'turquoise'
      semiround2Color = 'night'
      hexagonColor = 'gradient-blue'
      break
    case 'orange':
      roundColor = 'parma'
      quadColor = 'orange'
      semiround1Color = 'sienne'
      semiround2Color = 'sienne'
      hexagonColor = 'orange'
      break
    case 'sienne':
      roundColor = 'sienne'
      quadColor = 'gradient-sienne2'
      semiround1Color = 'parma'
      semiround2Color = 'parma'
      hexagonColor = 'gradient-sienne2'
      break
    default:
      roundColor = 'aqua'
      quadColor = 'purple'
      semiround1Color = 'purple'
      semiround2Color = 'night'
      hexagonColor = 'night'
  }

  switch (shape) {
    case 'pill':
      width = 530
      height = 677
      sizes = {"767": [250, 320], "1023": [379, 484], "1279": [448, 572]}
      break
    case 'hexagon-rotate2':
      width = 528
      height = 534
      sizes = {"767": [250, 253], "1023": [379, 384], "1279": [448, 453]}
      break
    default :
      width = 844
      height = 607
      sizes = {"415": [283, 238], "767": [556, 467], "1023": [436, 435], "1279": [558, 514]}
  }

  return (
    <div className={`relative shape-illu ${shape === 'hexagon-rotate2' ? 'shape-illu--hexagon' : shape === 'long-quad' ? `shape-illu--quad shape-illu--quad-${isReversed ? 'left' : 'right'} shaped-image shaped-image--long-quad${!isReversed ? '2' : ''}` : ''}`}>
      { shape === 'pill'
        ? <>
            <svg className={`absolute icon-${semiround1Color} shape-illu__semiround`} width="75" height="89">
              <use xlinkHref={semiroundShape.url} />
            </svg>
            <svg className={`absolute icon-${roundColor} shape-illu__round`} width="74" height="74">
              <use xlinkHref={roundShape.url} />
            </svg>
            <svg className={`absolute icon-${hexagonColor} shape-illu__hexagon`} width="80" height="80">
              <use xlinkHref={hexagonShape.url} />
            </svg>
          </>
        : <>
            { shape === 'hexagon-rotate2'
              ? <>
                  <svg className={`absolute icon-${semiround2Color} shape-illu__semiround2`} width="99" height="118">
                    <use xlinkHref={semiroundShape.url} />
                  </svg>
                  <svg className={`absolute icon-${quadColor} shape-illu__quad`} width="120" height="120">
                    <use xlinkHref={quadShape.url} />
                  </svg>
                </> 
              : ''
            }
          </>
      }
      
      <Image
        width={width}
        height={height}
        classes={`${shape !== 'long-quad' ? `shaped-image shaped-image--${shape}`: 'object-fit-image' } w-full`}
        sizes={sizes}
        image={image} />
    </div>
  )
}

export default ShapeImageIllu