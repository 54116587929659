import React, { useState } from 'react'
import  { Breakpoint } from 'react-socks'
import { useDispatch, useSelector } from 'react-redux'
import Scrollbar from 'react-scrollbars-custom'

import trads from '../../intl/trads.json'

import NavLevel from './navLevel'

import chevronIcon from '../../icons/chevron-right.svg'
import { changeNavImage } from '../../store/actions'

const MainNav = () => {
  const lang = useSelector(state => state.lang)
  const navImage = useSelector(state => state.navImage)
  const dispatch = useDispatch()

  // state
  const [globalLevel, setLevel] = useState(1)
  const [globalActiveSubnav, setActiveSubnav] = useState(null)
  const [globalActiveSubSubnav, setActiveSubSubnav] = useState(null)

  const changeLevel = (level, activeSubnav, activeSubSubnav, image) => {
    setLevel(level)
    setActiveSubnav(activeSubnav)
    setActiveSubSubnav(activeSubSubnav)

    const tempNavImage = [...navImage, image]
    dispatch(changeNavImage(tempNavImage))

    if (level > 1) {
      document.querySelector('.panel-nav').classList.add('has-subnav-open')
    }
  }

  const backNav = () => {
    let level = globalLevel - 1
    let activeSubnav = (level < 2) ? null : globalActiveSubnav

    setLevel(level)
    setActiveSubnav(activeSubnav)
    setActiveSubSubnav(null)

    const tempNavImage = [...navImage]
    tempNavImage.pop()
    dispatch(changeNavImage(tempNavImage))

    if (level < 2) {
      document.querySelector('.panel-nav').classList.remove('has-subnav-open')
    }
  }

  return (
    <nav className="flex main-nav-wrap">
      <button className={`panel-nav__back ${globalLevel > 1 ? 'is-visible' : '' }`} onClick={() => backNav()}>
        <svg className="icon-white" width="24" height="24">
          <use xlinkHref={chevronIcon.url} />
        </svg>
        {trads[lang].header.back}
      </button>

      <Breakpoint className="w-full" customQuery="(max-width: 1023px)">
        <NavLevel 
          level={globalLevel}
          activeSubnav={globalActiveSubnav}
          activeSubSubnav={globalActiveSubSubnav}
          lang={lang}
          changeLevel={changeLevel} />
      </Breakpoint>

      <Breakpoint className="w-full" customQuery="(min-width: 1024px)">
        <Scrollbar style={{ height: "100%" }}>
          <NavLevel 
            level={globalLevel}
            activeSubnav={globalActiveSubnav}
            activeSubSubnav={globalActiveSubSubnav}
            lang={lang}
            changeLevel={changeLevel} />
        </Scrollbar>
      </Breakpoint>
    </nav>
  )
}

export default MainNav