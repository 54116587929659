import React from 'react'

import chevronIcon from '../../icons/chevron-right.svg'

const ButtonLink = ({text}) => {
  return (
    <>
      <span>{text}</span>
      <svg className="icon-white" width="48" height="48">
        <use xlinkHref={chevronIcon.url} />
      </svg>
    </>
  )
}

export default ButtonLink