import React from 'react'
import closeIcon from '../icons/close.svg'

const ButtonClose = ({action, color, className, isSmall}) => {
  return (
    <button className={`button-close ${isSmall ? 'button-close--small' : ''} button-close--${color} ${className}`} onClick={action}>
      <svg width="16" height="16">
        <use xlinkHref={closeIcon.url} />
      </svg>
      <span className="sr-only">Fermer</span>
    </button>
  )
}

export default ButtonClose
