import { connectHits } from 'react-instantsearch-dom';
import React from 'react'
import { useSelector } from 'react-redux'

import SearchPanelHitsSection from './searchPanelHitsSection'

import OrderHits from '../../utils/orderHits'

const Hits = ({hits}) => {
  const mainTag = useSelector(state => state.currentMainTag)
  const emptyOther = useSelector(state => state.laboEmptyOther)

  let events = hits.filter(hit => hit.contentType === 'event')
  let news = hits.filter(hit => hit.contentType === 'news')
  let pages = hits.filter(hit => hit.contentType === 'page')
  let docs = hits.filter(hit => hit.contentType === 'doc')
  let solutions = hits.filter(hit => hit.contentType === 'solution')
  let offers = hits.filter(hit => hit.contentType === 'offer')
  let emptyResults = false

  if (events.length === 0 && news.length === 0 && pages.length === 0 && docs.length === 0) {
    events = emptyOther.events
    news = emptyOther.news
    pages = emptyOther.pages
    emptyResults = true
  } else {
    events = OrderHits(events, mainTag)
    news = OrderHits(news, mainTag)
    pages = OrderHits(pages, mainTag)
    docs = OrderHits(docs, mainTag)
  }

  return (
    <>
      { news && news.length > 0 &&
        <SearchPanelHitsSection
          contentType="news"
          hits={news}
          emptyResults={emptyResults}
        />
      }
      { events && events.length > 0 &&
        <SearchPanelHitsSection
          contentType="event"
          hits={events}
          emptyResults={emptyResults}
        />
      }
      { pages && pages.length > 0 &&
        <SearchPanelHitsSection
          contentType="page"
          hits={pages}
          emptyResults={emptyResults}
        />
      }
      { docs && docs.length > 0 &&
        <SearchPanelHitsSection
          contentType="doc"
          hits={docs}
          emptyResults={emptyResults}
        />
      }
      { solutions && solutions.length > 0 &&
        <SearchPanelHitsSection
          contentType="solution"
          hits={solutions}
          emptyResults={emptyResults}
        />
      }
      { offers && offers.length > 0 &&
        <SearchPanelHitsSection
          contentType="offer"
          hits={offers}
          emptyResults={emptyResults}
        />
      }
    </>
  )
}

const SearchPanelHits = connectHits(Hits)

export default SearchPanelHits