import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useEventListener } from 'react-recipes'

import scrollManager from '../../utils/scrollManager'

import trads from '../../intl/trads.json'
import burgerIcon from '../../icons/burger.svg'
import searchIcon from '../../icons/search.svg'

import PanelNav from './panelNav'
import SearchPanel from '../search/searchPanel'
import ButtonClose from '../buttonClose'

import {changeLang, setHasSearchPanelOpen, setHasMainNavOpen} from '../../store/actions'
import { useEffect } from 'react'

const Header = ({frUrl, enUrl, theme = 'reg', isHome = false, isSolution = false}) => {
  // local state
  const [isFixed, setIsFixed] = useState(false)

  // redux
  const lang = useSelector(state => state.lang)
  const hasSearchPanelOpen = useSelector(state => state.hasSearchPanelOpen)
  const hasMainNavOpen = useSelector(state => state.hasMainNavOpen)
  const dispatch = useDispatch()

  // managed fixed header
  const handleScroll = () => {
    let checkIfFixed = (window.pageYOffset > 20) ? true : false
    setIsFixed(checkIfFixed)
  }

  const calcHeightMobile = () => {
    const vh = window.innerHeight * 0.01
    document.body.style.setProperty('--vh', `${vh}px`)
  }

  useEffect(() => {
    dispatch(setHasSearchPanelOpen(false))
    calcHeightMobile()
  }, [dispatch])

  const isLight = theme === 'text-white'

  // close all panels on logo click
  const closePanels = () => {
    dispatch(setHasSearchPanelOpen(false))
    dispatch(setHasMainNavOpen(false))
  }

  // toggle main nav panel or profile
  const togglePanel = (type) => {
    if (type === 'nav') {
      dispatch(setHasMainNavOpen(!hasMainNavOpen))
    } else {
      dispatch(setHasSearchPanelOpen(!hasSearchPanelOpen))

      if (!hasSearchPanelOpen) {
        // TODO: refactor if possible
        setTimeout(() => {
          document.querySelector('#laboInput').focus()
        }, 50)
      }
    }

    if (
      (!hasMainNavOpen && type === 'nav') ||
      (!hasSearchPanelOpen && type === 'labo')) {
        setTimeout(() => {
          scrollManager.stop()
        }, 400)
    } else {
      scrollManager.restart()
    }
  }
  
  // scroll event listener
  useEventListener('scroll', handleScroll)
  useEventListener('resize', calcHeightMobile)
  useEventListener('orientationchange', calcHeightMobile)

  return (
    <>
      <header className={`header ${!isHome && theme === 'bg-white' && !isFixed ? 'header--border' : ''} ${isSolution && !isFixed && !hasSearchPanelOpen ? 'header--solution' : ''} ${isLight && !isFixed  && !hasSearchPanelOpen? 'header--light' : ''} ${theme === 'bg-white' || isFixed || hasSearchPanelOpen ? 'header--white' : ''} ${hasMainNavOpen ?'is-open' : ''}`}>
        <div className="fluid-grid header__inner">
          {/* burger + logo */}
          <div className="header__left flex items-center">
              { !hasMainNavOpen ? 
                <button className="header__burger" onClick={() => { togglePanel('nav');  hasSearchPanelOpen && togglePanel('labo') }}>
                  <svg className="icon-night" width="28" height="21">
                    <use xlinkHref={burgerIcon.url} />
                  </svg>
                  <span className="sr-only">Menu</span>
                </button>
                : <ButtonClose color='white' action={() => togglePanel('nav')} />
              }
              <Link className="block flex-shrink-0" to={lang === 'fr' ? '/' : '/en'} onClick={() => closePanels()}>
                <svg className="header__logo" width="130" height="44" viewBox="0 0 130 44">
                  <path className="header__logo-baseline" fill="#EC8C2E" d="M61.839 40.934c.411.684 1.17 1.144 2.18 1.144.958 0 1.294-.447 1.294-.858 0-.759-.971-.946-2.179-1.393-1.245-.448-2.105-1.12-2.105-2.514 0-1.555 1.395-2.45 2.99-2.45 1.469 0 2.365.696 2.901 1.443l-1.283 1.02c-.311-.436-.822-.747-1.569-.747-.71 0-1.146.299-1.146.71 0 .721.685.746 2.092 1.293 1.57.598 2.217 1.332 2.217 2.576 0 1.53-1.02 2.65-3.225 2.65-1.868 0-3.027-.87-3.562-1.916l1.395-.958zM72.636 42.103c1.519 0 2.714-1.22 2.714-2.75 0-1.505-1.183-2.749-2.714-2.749-1.532 0-2.715 1.232-2.715 2.75 0 1.555 1.183 2.75 2.715 2.75m0-7.241c2.577 0 4.632 1.978 4.632 4.49 0 2.526-2.055 4.492-4.632 4.492-2.566 0-4.633-1.966-4.633-4.491 0-2.513 2.067-4.491 4.633-4.491M78.55 35h1.919v6.93h3.387v1.74h-5.305zM84.59 35h1.918v4.963c0 1.518.71 2.103 1.781 2.103 1.059 0 1.769-.585 1.769-2.103V35h1.917v5.163c0 2.451-1.668 3.646-3.686 3.646-2.03 0-3.698-1.195-3.698-3.646V35zM95.25 36.741h-2.353v-1.742h6.588v1.742h-2.317v6.93h-1.917zM100.543 43.671h1.918V35h-1.918zM108.439 42.103c1.519 0 2.714-1.22 2.714-2.75 0-1.505-1.183-2.749-2.714-2.749-1.532 0-2.715 1.232-2.715 2.75 0 1.555 1.183 2.75 2.715 2.75m0-7.241c2.577 0 4.632 1.978 4.632 4.49 0 2.526-2.055 4.492-4.632 4.492-2.566 0-4.633-1.966-4.633-4.491 0-2.513 2.067-4.491 4.633-4.491M114.354 35h1.706l4.16 5.386V35h1.855v8.672h-1.57l-4.296-5.536v5.536h-1.855zM124.416 40.934c.41.684 1.17 1.144 2.179 1.144.959 0 1.295-.447 1.295-.858 0-.759-.971-.946-2.18-1.393-1.245-.448-2.104-1.12-2.104-2.514 0-1.555 1.395-2.45 2.989-2.45 1.47 0 2.366.696 2.902 1.443l-1.283 1.02c-.311-.436-.822-.747-1.57-.747-.71 0-1.145.299-1.145.71 0 .721.685.746 2.092 1.293 1.57.598 2.217 1.332 2.217 2.576 0 1.53-1.02 2.65-3.225 2.65-1.868 0-3.027-.87-3.562-1.916l1.395-.958z"/>
                  <path className="header__logo-name" fill="#312E60" d="M10.568 9.747v17.637H6.905V9.747H.017V6.554h17.438v3.193zM34.184 15.665c-.219-3.659-2.559-6.234-6.022-6.234-3.705 0-6.022 2.888-6.335 6.234h12.357zm-12.357 2.89c.094 3.878 3.116 5.951 6.622 5.951 2.535 0 4.242-1.188 5.713-2.877l2.567 2.252c-2.098 2.471-4.711 3.878-8.405 3.878-6.418 0-10.41-4.378-10.41-10.727 0-6.35 4.112-10.853 10.28-10.853 6.042 0 9.747 4.807 9.747 10.874 0 .532-.031 1.22-.094 1.502h-16.02zM50.51 9.744h-5.898v7.23h5.961c2.13 0 3.57-1.478 3.57-3.48 0-2.095-1.503-3.75-3.632-3.75zm3.851 17.64-4.295-7.52h-5.454v7.52H40.95V6.554h9.656c4.601 0 7.294 2.969 7.294 6.722 0 2.97-1.879 5.2-4.164 6.076l4.728 8.032H54.36zM76.615 15.665c-.219-3.659-2.559-6.234-6.022-6.234-3.705 0-6.022 2.888-6.335 6.234h12.357zm-12.357 2.89c.094 3.878 3.116 5.951 6.622 5.951 2.535 0 4.242-1.188 5.713-2.877l2.567 2.252c-2.098 2.471-4.711 3.878-8.405 3.878-6.418 0-10.41-4.378-10.41-10.727 0-6.35 4.112-10.853 10.28-10.853 6.042 0 9.747 4.807 9.747 10.874 0 .532-.031 1.22-.094 1.502h-16.02zM77.04.04c.175 0 .318.128.343.295a2.298 2.298 0 0 1-2.28 2.594H63.676a.346.346 0 0 1-.342-.295A2.298 2.298 0 0 1 65.614.04h11.427zM103.152 15.637h-9.674v2.889h6.073c.216 3.211-1.944 5.98-6.073 5.98-4.112 0-6.512-3.18-6.512-7.537 0-4.351 2.393-7.528 6.495-7.537 2.373.004 4.308 1.036 5.496 3.033l3.005-1.814c-1.816-2.783-4.727-4.472-8.484-4.472-6.386 0-10.362 4.691-10.362 10.79 0 6.099 3.976 10.79 10.362 10.79 6.324 0 9.83-4.372 9.83-9.644 0-.559-.063-2.044-.156-2.478M116.397 24.507c-4.112 0-6.511-3.18-6.511-7.538 0-4.351 2.41-7.537 6.512-7.537h-.001c4.102 0 6.609 3.186 6.609 7.537 0 4.357-2.496 7.538-6.609 7.538zm10.272-2.622V6.555h-3.663v1.97c-1.702-1.49-3.941-2.346-6.609-2.346-6.386 0-10.362 4.691-10.362 10.79 0 6.099 3.976 10.79 10.362 10.79 3.107 0 5.532-1.057 7.197-2.756 1.829 4.145 6.199 2.067 6.199 2.067l-.558-2.953c-.655.279-1.714.403-2.19-.153-.377-.443-.376-2.08-.376-2.08z"/>
                </svg>
              </Link>
          </div>

          {/* search + contact + lang */}
          <div className="header__right flex items-center">
            <button className="mr-6" onClick={() => togglePanel('labo')}>
              <svg className="icon-night" width="36" height="36">
                <use xlinkHref={searchIcon.url} />
              </svg>
              <span className="sr-only">Search</span>
            </button>

            <Link to={(lang === 'fr' ? '/contact' : '/en/contact')} className="header__pill hidden md:block font-alternate">
              { trads[lang].header.contact }
            </Link>
            <div className={`lang header__lang ${hasMainNavOpen || (isLight && !isFixed && !hasSearchPanelOpen ) ? 'lang--white' : ''} font-alternate md:ml-9`}>
              <Link className={`lang__item ${(lang === 'fr' ? 'is-active' : '')} ${frUrl === null ? 'is-disabled' : ''}`} onClick={() => dispatch(changeLang('fr'))} to={frUrl === null ? '/' : frUrl}>Fr</Link>
              <Link className={`lang__item ${(lang === 'en' ? 'is-active' : '')} ${enUrl === null ? 'is-disabled' : ''}`} onClick={() => dispatch(changeLang('en'))} to={enUrl === null ? '/en' : enUrl}>En</Link>
            </div>
          </div>
        </div>

        <PanelNav isOpen={hasMainNavOpen} />
      </header>
      <SearchPanel  isOpen={hasSearchPanelOpen} />
    </>
  )
}

export default Header
