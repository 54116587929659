import isMobile from 'ismobilejs'

const scrollManager = () => {
  let storedScrollY = 0
  let isIOS = false

  if(typeof window !== 'undefined') {
   isIOS = isMobile(window.navigator).apple.device
  }
  
  return {
    stop: () => {
      storedScrollY = window.pageYOffset
      document.body.classList.add('no-scroll-y')

      if(isIOS) {
        document.body.classList.add('no-scroll-y-ios')
        document.documentElement.classList.add('no-scroll-y')
      }
    },

    restart: () => {
      document.body.classList.remove('no-scroll-y')

      if(isIOS) {
        document.body.classList.remove('no-scroll-y-ios')
        document.documentElement.classList.remove('no-scroll-y')
        window.scrollTo(0, storedScrollY)
      }
    },
  }
}

export default scrollManager()