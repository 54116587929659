import React from 'react'
import algoliasearch from 'algoliasearch/lite'
import { useSelector, useDispatch } from 'react-redux'
import { InstantSearch, Configure } from 'react-instantsearch-dom'

import ButtonClose from '../buttonClose'

import CustomSearchBox from './searchbox'
import SearchPanelResults from './searchPanelResults'

import scrollManager from '../../utils/scrollManager'
import {setHasSearchPanelOpen} from '../../store/actions'

const SearchPanel = React.memo( ({isOpen}) => {
  const dispatch = useDispatch()
  const lang = useSelector(state => state.lang)
  const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY)

  return (
    <div className={`search-panel ${isOpen && 'is-open'}`}>
      <InstantSearch 
        searchClient={searchClient}
        indexName={process.env.GATSBY_ALGOLIA_INDEX}>
        <Configure 
          filters={`node_locale:${lang}`}
          hitsPerPage="1000"
        />

        <div className="search-panel__top">
          <ButtonClose isSmall={true} action={() => { dispatch(setHasSearchPanelOpen(false)); scrollManager.restart()}} color="night" className="search-panel__close" />

          <div className="fluid-grid">
            <CustomSearchBox />
          </div>
        </div>

        <SearchPanelResults/>
      </InstantSearch>
    </div>
  )
}, (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen)

export default SearchPanel
