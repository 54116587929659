const OrderHits = (hits, mainTag, profile) => {
  hits.forEach(hit => {
    let weight = 0

    if(hit.mainTag === mainTag && ((profile !== '' && profile !== null) && (hit.profile !== null && profile === hit.profile))) {
      weight = 4
    } else if (hit.mainTag === mainTag) {
      weight = 3
    } else if ( (profile !== '' || profile !== null) && (hit.profile !== null && profile === hit.profile)) {
      weight = 2
    } else if (hit.secondaryTags !== null ) {
      if (hit.secondaryTags.includes(mainTag)) {
        weight= 1
      }
    }

    hit.weight = weight
  })
  
  const orderedHits = hits.sort((a, b) => (a.weight <= b.weight) ? 1 : -1)
  
  return orderedHits
}

export default OrderHits