import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'

import MainNav from './mainNav'
import Image from '../image'

import trads from '../../intl/trads.json'

import { setHasMainNavOpen } from '../../store/actions'

import scrollManager from '../../utils/scrollManager'

import twitterIcon from '../../icons/twitter.svg'
import linkedinIcon from '../../icons/linkedin.svg'
import semiroundShape from '../../icons/semiround-shape.svg'
import hexagonShape from '../../icons/hexagon-shape.svg'

const PanelNav= ({ isOpen, togglePanel}) => {
  const lang = useSelector(state => state.lang)
  const navImage = useSelector(state => state.navImage)
  let globalSettings = ''
  const dispatch = useDispatch()

  const click = () => {
    dispatch(setHasMainNavOpen(false))
    scrollManager.restart()
  }

  const datas = useStaticQuery(graphql`
    query {
      globalSettings: allContentfulGlobalSettingsSolutions {
        nodes {
          node_locale
          title
          linkedin
          twitter
        }
      }
    }
  `)


  datas.globalSettings.nodes.forEach(item => {
    if (item.node_locale === lang && item.title !== 'dummy content' && item.title !== 'Dummy content') {
      globalSettings = item
    }
  })

  return (
    <div className={`panel-nav flex ${isOpen ? 'is-visible' : ''}`}>
      <div className="fluid-grid flex">
        <div className="row flex-grow md:flex-grow-0">
          <div className="col-12 md-col-10 mx-auto lg-col-7 xl-col-6 xl-col-offset-1 flex flex-col justify-between">
            <MainNav togglePanel />
            
            {/* contact + RS */}
            <div className="flex items-center justify-between lg:justify-start">
              <ul className="panel-nav__socials">
                {globalSettings.twitter &&
                  <li>
                    <a className="panel-nav__social" href={globalSettings.twitter} target="_blank" rel="noopener noreferrer">
                      <span className="sr-only">Twitter</span>
                      <svg className="icon-white" width="24" height="24">
                        <use xlinkHref={twitterIcon.url} />
                      </svg>
                    </a>
                  </li>
                }

                {globalSettings.linkedin &&
                  <li>
                    <a className="panel-nav__social" href={globalSettings.linkedin} target="_blank" rel="noopener noreferrer">
                      <span className="sr-only">Linkedin</span>
                      <svg className="icon-white" width="24" height="24">
                        <use xlinkHref={linkedinIcon.url} />
                      </svg>
                    </a>
                  </li>
                }
              </ul>

              <Link className="header__pill font-alternate" onClick={click} to={(lang === 'fr' ? '/contact' : '/en/contact')}>{ trads[lang].header.contact }</Link>
            </div>
          </div>
          <div className="hidden lg:block col-12 md-col-4 md-col-offset-1">
              <div className="panel-nav__illu" >
                { navImage &&
                  <Image 
                    image={navImage[navImage.length -1]}
                    width={500}
                    height={500}
                    classes="shaped-image shaped-image--quad"
                    sizes={{"1023": [0, 0] }} />
                }
                <svg className="absolute icon-orange icon-round">
                  <use xlinkHref={semiroundShape.url} />
                </svg>
                <svg className="absolute icon-beige icon-hexagon">
                  <use xlinkHref={hexagonShape.url} />
                </svg>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PanelNav
