import { useStaticQuery, graphql } from 'gatsby'

const GetNewsroomUrls = () => {
  const datas = useStaticQuery(graphql`
    query {
      newsroomList: allContentfulNewsroomList(filter: {distributionChannel: {elemMatch: {title: {eq: "Terega solutions"}}}}) {
        nodes {
          slug
          type
          node_locale
        }
      }
    }
  `)

  let newsroomUrls = []

  datas.newsroomList.nodes.forEach(item => {
    if (newsroomUrls[item.type] === undefined) {
      newsroomUrls[item.type] = []
    }
    newsroomUrls[item.type][item.node_locale] = `${item.node_locale === 'en' ? 'en/' : ''}${item.slug}`
  })

  return newsroomUrls
}

export default GetNewsroomUrls