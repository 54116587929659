import React from 'react'
import { Link } from 'gatsby'
import LinkIcon from '../linkIcon'
import { useSelector, useDispatch } from 'react-redux'

import trads from '../../intl/trads.json'

import Hit from './hit'

import {setHasSearchPanelOpen} from '../../store/actions'
import scrollManager from '../../utils/scrollManager'

const SearchPanelHitsSection = ({contentType, hits, emptyResults= false}) => {
  const dispatch = useDispatch()

  const lang = useSelector(state => state.lang)
  const query = useSelector(state => state.currentSearchQuery)

  return (
    <div className="search-panel__section">
      <div className="flex justify-between items-center mb-6">
        <p className="title title-slice mr-2">{trads[lang].search.titleContentTypes[contentType]} { hits.length > 3 && <>({hits.length})</> }</p>

        { hits.length > 3 &&
          <Link
            className="flex-shrink-0"
            onClick={() => { dispatch(setHasSearchPanelOpen(false)); scrollManager.restart()}}
            to={lang === 'fr' ? `/recherche?q=${encodeURI(query)}` : `/en/search?q=${encodeURI(query)}`}
            state={{filters: [contentType] ,searchQuery: query}}>
            <LinkIcon
              text={`<span class="hidden md:block">${trads[lang].search.linkContentTypes[contentType]}</span>
              <span class="md:hidden" aria-hidden="true">${trads[lang].search.seeAllMobile}</span>`}
            />
          </Link>
        }
      </div>

      <ul>
        { hits.slice(0, 3).map((hit, i) => {
          hit.contentType = contentType
          
          return(
            <li key={i} className="mb-6"><Hit hit={hit} emptyResults={emptyResults} /></li>
          )
        })}
      </ul>
    </div>
  )
}

export default SearchPanelHitsSection
