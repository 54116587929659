import React from 'react'

const CookiesSettings = ({text, className}) => {
  const showCookiesPreferences = () => {
    document.querySelectorAll('.onetrust-pc-dark-filter, .otPcCenter').forEach(item => {
      item.classList.remove('ot-hide')
      item.style.visibility = 'visible'
      item.style.opacity = 1
      item.style.display = 'block'
    })
  }

  return (
    <button className={className} onClick={() => showCookiesPreferences()}>{text}</button>
  )
}

export default CookiesSettings