import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useStaticQuery, graphql} from 'gatsby'

import transformLink from '../utils/transformLink'
import scrollManager from '../utils/scrollManager'

import ButtonClose from './buttonClose'
import LinkIcon from './linkIcon'
import ContactItem from './contactItem'

const AlertPopin = () => {
  //redux lang
  const lang = useSelector(state => state.lang)

  const [isVisible, setIsVisible] = useState(false)

  let content = null

  const datas = useStaticQuery(graphql`
    query {
      globalSettings: allContentfulGlobalSettingsSolutions {
        nodes {
          title
          node_locale
          popinDisplay
          popinTitle
          popinChapo {
            popinChapo
          }
          popinTxt {
            popinTxt
          }
          popinCta {
            pageLink {
              ... on Node {
                ... on ContentfulEvent {
                  title
                  node_locale
                  internal {
                    type
                  }
                } 
                ... on ContentfulNews {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulLanding {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulOffer {
                  title
                  solution {
                    title
                  }
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulSolution {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulHomepageSolutions {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulContact {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulSimulator {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulHub {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulPage {
                  title
                  slug
                  customType
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulNewsroomList {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
              }
            }
            customLink
            label
            assetLink {
              file {
                url
              }
            }
          }
          popinContact {
            title
            contactInformations {
              title
              icon
              link {
                pageLink {
                  ... on Node {
                    ... on ContentfulLanding {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulOffer {
                      title
                      solution {
                        title
                      }
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulSolution {
                      title
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulEvent {
                      title
                      node_locale
                      internal {
                        type
                      }
                    } 
                    ... on ContentfulNews {
                      title
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulHomepageSolutions {
                      title
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulContact {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulSimulator {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulHub {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulPage {
                      title
                      slug
                      customType
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulNewsroomList {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                  }
                }
                customLink
                label
                assetLink {
                  file {
                    url
                  }
                }
              }
              content {
                raw
              }
            }
          }
        }
      }
    }
  `)

  datas.globalSettings.nodes.forEach(item => {
    if (item.node_locale === lang && item.title !== 'Dummy content') {
      content = item
    }
  })

  const ctaUrl =  transformLink(content.popinCta, true, false).url

  useEffect(() => {
    if(localStorage.getItem('notFirstVisit') === null) {
      setIsVisible(true)
    }

    setTimeout(() => {
      if(localStorage.getItem('notFirstVisit') === null && content.popinDisplay) {
        scrollManager.stop()
        localStorage.setItem('notFirstVisit', 'true');
      }
    }, 280)
  }, [content.popinDisplay])

  return (
    <>
      { (isVisible && content.popinDisplay) &&
        <div className="alert-popin">
          <div className="col-12 md-col-10 xl-col-8 mx-auto">
            <div className="alert-popin__inner bg-white relative my-8">
              <ButtonClose
                color="purple"
                className="alert-popin__close"
                action={() => {setIsVisible(false); scrollManager.restart()}}
              />
              <div className="px-6 md:px-8 py-8">
                { content.popinTitle &&
                  <span className="title title-small mb-4 pr-8 block">{content.popinTitle}</span>
                }
                { content.popinChapo &&
                  <p className="alert-popin__chapo mb-4">{content.popinChapo.popinChapo}</p>
                }
                { content.popinTxt &&
                  <p className="paragraph mb-4">{content.popinTxt.popinTxt}</p>
                }

                { content.popinCta &&
                  <LinkIcon
                    link={ctaUrl}
                    text={ content.popinCta.label }
                    color="night"
                    iconColor="purple"
                  />
                }
              </div>
              { content.popinContact &&
                <div className="bg-orange px-6 md:px-10 pt-6 pb-2">
                  <p className="text-white alert-popin__contact-title mb-4">{content.popinContact.title}</p>
                  
                  <div className="md:flex flex-wrap">
                    { content.popinContact.contactInformations.map((contact, i) => {
                      return (
                        <div key={i} className="alert-popin__contact-item">
                          <ContactItem type="popin" data={contact} color="white" pos={i + 1} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default AlertPopin
