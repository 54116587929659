import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useStaticQuery, graphql, Link } from 'gatsby'

import transformLink from '../utils/transformLink'

import trads from '../intl/trads.json'

import twitterIcon from '../icons/twitter.svg'
import linkedinIcon from '../icons/linkedin.svg'
import semiroundShape from '../icons/semiround-shape.svg'
import hexagonShape from '../icons/hexagon-shape.svg'

import CookiesSettings from './cookiesSettings'
import {changeLang} from '../store/actions'

const Footer = ({frUrl, enUrl}) => {
  let globalSettings = null
  const lang = useSelector(state => state.lang)
  const dispatch = useDispatch()

  const datas = useStaticQuery(graphql`
    query {
      globalSettings: allContentfulGlobalSettingsSolutions {
        nodes {
          node_locale
          title
          linkedin
          twitter
          navFooter {
            label
            pageLink {
              ... on Node {
                ... on ContentfulEvent {
                  title
                  node_locale
                  internal {
                    type
                  }
                } 
                ... on ContentfulNews {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulHomepageSolutions {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulContact {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulSimulator {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulHub {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulPage {
                  title
                  slug
                  customType
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulNewsroomList {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulLanding {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulOffer {
                  title
                  solution {
                    title
                  }
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulSolution {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
              }
            }
            customLink
            assetLink {
              file {
                url
              }
            }
          }
          legalNavFooter {
            label
            pageLink {
              ... on Node {
                ... on ContentfulEvent {
                  title
                  node_locale
                  internal {
                    type
                  }
                } 
                ... on ContentfulNews {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulHomepageSolutions {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulContact {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulSimulator {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulHub {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulPage {
                  title
                  slug
                  customType
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulNewsroomList {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulLanding {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulOffer {
                  title
                  solution {
                    title
                  }
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulSolution {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
              }
            }
            customLink
            assetLink {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  datas.globalSettings.nodes.forEach(item => {
    if (item.node_locale === lang && item.title !== 'dummy content' && item.title !== 'Dummy content') {
      globalSettings = item
    }
  })

  return (
    <footer className="footer">
      <div className="fluid-grid">
        <div className="footer__wrapper xl:flex justify-between items-end">
          {/* left part logo + mentions */}
          <div>
            <Link className="mb-11 block" to={lang === 'fr' ? '/' : '/en'}>
              <img width="248" className="footer__logo" src="/img/logo.svg" alt="Terega Solutions" />
            </Link>
            <p className="mentions footer__mentions">©{new Date().getFullYear()} Terega Solutions | Tout droit réservé</p>
          </div>

          {/* right part: nav + socials + legals */}
          <div className="relative">
            <svg className="icon-sienne footer__deco footer__deco--1" width="46" height="54">
              <use xlinkHref={semiroundShape.url} />
            </svg>
            <svg className="icon-gradient-blue footer__deco footer__deco--2" width="60" height="60">
              <use xlinkHref={hexagonShape.url} />
            </svg>
            <div className="md:flex items-end mb-4">
              {/* nav footer */}
              <ul className="flex flex-wrap">
                {globalSettings.navFooter.map((link, i) => {
                  const url = transformLink(link, true, false).url     
                  return (
                    <li key={i} className="mr-6"><Link to={url} className="footer__link">{link.label}</Link></li>
                  ) 
                })}
              </ul>
              {/* lang */}
              <div className="lang font-alternate mt-4 md:mt-0 md:ml-2">
                <Link className={`lang__item ${(lang === 'fr' ? 'is-active' : '')} ${frUrl === null && 'is-disabled'}`} onClick={() => dispatch(changeLang('fr'))} to={frUrl === null ? '/' : frUrl}>Fr</Link>
                <Link className={`lang__item ${(lang === 'en' ? 'is-active' : '')} ${enUrl === null && 'is-disabled'}`} onClick={() => dispatch(changeLang('en'))} to={enUrl === null ? '/en' : enUrl}>En</Link>
              </div>
            </div>

            <div className="md:flex items-end lg:justify-between">
              <ul className="flex footer__socials mr-6 xl:mr-8">
                {globalSettings.twitter &&
                  <li className="mr-3">
                    <a className="link-social center" href={globalSettings.twitter} target="_blank" rel="noopener noreferrer">
                      <span className="sr-only">Compte Twitter</span>
                      <svg className="icon-night" width="26" height="26">
                        <use xlinkHref={twitterIcon.url} />
                      </svg>
                    </a>
                  </li>
                }

                {globalSettings.linkedin &&
                  <li>
                    <a className="link-social center" href={globalSettings.linkedin} target="_blank" rel="noopener noreferrer">
                      <span className="sr-only">Compte Linkedin</span>
                      <svg className="icon-night" width="26" height="26">
                        <use xlinkHref={linkedinIcon.url} />
                      </svg>
                    </a>
                  </li>
                }
              </ul>
              <ul className="flex flex-wrap mentions">
                <li className="mr-4 md:mr-11" ><CookiesSettings text={ trads[lang].footer.cookies } /></li>
                {globalSettings.legalNavFooter.map((link, i) => {
                  const url = transformLink(link, true, false).url     
                  return (
                    <li className={i !== globalSettings.legalNavFooter.length - 1 ? 'mr-4 md:mr-11' : '' } key={i}><Link to={url}>{link.label}</Link></li>
                  ) 
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
