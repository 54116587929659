import React from 'react'
import { Link } from 'gatsby'
import { useDispatch } from 'react-redux'

import chevronIcon from '../../icons/chevron-right.svg'

import scrollManager from '../../utils/scrollManager'

import  { setHasMainNavOpen } from '../../store/actions'

const DirectLink = ({text, link, contentType = false, type = 'reg'}) => {
  const dispatch = useDispatch()

  const click = () => {
    dispatch(setHasMainNavOpen(false))
    scrollManager.restart()
  }
  
  return (
    <>
    { contentType === 'ContentfulSimulator' || contentType === 'ContentfulContact' ?
      <a href={link} className="main-nav__link" onClick={click}>
        <span>{text}</span>
        {type !== 'level-more' &&
          <svg className="icon-white" width="48" height="48">
            <use xlinkHref={chevronIcon.url} />
          </svg>
        }
      </a>
    : <Link to={link} className="main-nav__link" onClick={click}>
        <span>{text}</span>
        {type !== 'level-more' &&
          <svg className="icon-white" width="48" height="48">
            <use xlinkHref={chevronIcon.url} />
          </svg>
        }
      </Link>}
    </>
  )
}

export default DirectLink