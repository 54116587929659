import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import slugify from 'slugify'
import { useDispatch, useSelector } from 'react-redux'

import DirectLink from './directLink'
import ButtonLink from './buttonLink'

import transformLink from '../../utils/transformLink'
import { changeNavImage } from '../../store/actions'

const NavLevel = ({level, activeSubnav, activeSubSubnav, changeLevel}) => {
  const [navigation, setNavigation] = useState(null)
  const lang = useSelector(state => state.lang)
  const dispatch = useDispatch()

  const datas = useStaticQuery(graphql`
    query {
      navigations: allContentfulNavigationLevel(filter: {firstLevel: {eq: true}, distributionChannel: {title: {eq: "Terega solutions"}}, profile: {eq: false}}) {
        nodes {
          image {
            altImage
            file {
              file {
                url
              }
            }
          }
          links {
            label
            subnav {
              image {
                altImage
                file {
                  file {
                    url
                  }
                }
              }
              links {
                label
                pageLink {
                  ... on Node {
                    ... on ContentfulEvent {
                      title
                      node_locale
                      internal {
                        type
                      }
                    } 
                    ... on ContentfulLanding {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulOffer {
                      title
                      solution {
                        title
                      }
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulSolution {
                      title
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulNews {
                      title
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulHomepageSolutions {
                      title
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulContact {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulSimulator {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulHub {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulPage {
                      title
                      slug
                      customType
                      node_locale
                      internal {
                        type
                      }
                    }
                    ... on ContentfulNewsroomList {
                      title
                      slug
                      node_locale
                      internal {
                        type
                      }
                    }
                  }
                }
                customLink
                subnav {
                  image {
                    altImage
                    file {
                      file {
                        url
                      }
                    }
                  }
                  links {
                    customLink
                    label
                    pageLink {
                      ... on Node {
                        ... on ContentfulEvent {
                          title
                          node_locale
                          internal {
                            type
                          }
                        } 
                        ... on ContentfulNews {
                          title
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulHomepageSolutions {
                          title
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulContact {
                          title
                          slug
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulSimulator {
                          title
                          slug
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulHub {
                          title
                          slug
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulPage {
                          title
                          slug
                          customType
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulNewsroomList {
                          title
                          slug
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulLanding {
                          title
                          slug
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulOffer {
                          title
                          solution {
                            title
                          }
                          node_locale
                          internal {
                            type
                          }
                        }
                        ... on ContentfulSolution {
                          title
                          node_locale
                          internal {
                            type
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            pageLink {
              ... on Node {
                ... on ContentfulEvent {
                  title
                  mainImage {
                    altImage
                    file {
                      file {
                        url
                      }
                    }
                  }
                  node_locale
                  internal {
                    type
                  }
                } 
                ... on ContentfulNews {
                  title
                  mainImage {
                    altImage
                    file {
                      file {
                        url
                      }
                    }
                  }
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulHomepageSolutions {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulContact {
                  title
                  mainImage {
                    altImage
                    file {
                      file {
                        url
                      }
                    }
                  }
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulSimulator {
                  title
                  mainImage {
                    altImage
                    file {
                      file {
                        url
                      }
                    }
                  }
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulHub {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulLanding {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulOffer {
                  title
                  solution {
                    title
                  }
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulSolution {
                  title
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulPage {
                  title
                  mainImage {
                    altImage
                    file {
                      file {
                        url
                      }
                    }
                  }
                  slug
                  customType
                  node_locale
                  internal {
                    type
                  }
                }
                ... on ContentfulNewsroomList {
                  title
                  slug
                  node_locale
                  internal {
                    type
                  }
                }
              }
            }
            customLink
          }
          node_locale
        }
      }
    }
  `)
  
  useEffect(() => {
    datas.navigations.nodes.forEach(item => {
      if (item.node_locale === lang) {
        setNavigation(item)

        dispatch(changeNavImage(new Array(item.image)))
      }
    })
  }, [datas, dispatch, lang])

  return (
    <ul className='main-nav'>
      { navigation && navigation.links.map((item, i) => { 
        const slugItem = slugify(item.label).toLowerCase()
        const {url, contentTypeContentful} = transformLink(item, true, false)

        return (
          <li className={`main-nav__item main-nav__item--level-1 ${level > 1 ? 'is-hidden' : ''}`} key={i}>
            {item.subnav !== null
              ? 
                <>
                  <button className="main-nav__link" onClick={() => changeLevel(2, slugItem, null, item.subnav.image) }>
                    <ButtonLink text={item.label} />
                  </button>
                  <div className={`main-nav__subnav ${activeSubnav === slugItem ? 'is-active': ''}`}>
                    <div className={`main-nav__item main-nav__item--level-more ${activeSubnav === slugItem ? 'is-visible': ''} ${activeSubnav === slugItem && level === 3 ? 'is-hidden': ''}`}>
                      <DirectLink text={item.label} link={url} contentType={contentTypeContentful} type="level-more" />
                    </div>
                    <ul className='main-nav__subnav-list'>
                      {item.subnav.links.map((item, i) => { 
                        const slugSubItem = slugify(item.label).toLowerCase()
                        const {url, contentTypeContentful} = transformLink(item, true, false)  

                        return (
                          <li className={`main-nav__item main-nav__item--level-more ${activeSubnav === slugItem ? 'is-visible': ''} ${activeSubnav === slugItem && level === 3 ? 'is-hidden': ''}`} key={i}>
                            {item.subnav !== null
                              ? 
                                <>
                                  <button className="main-nav__link" onClick={() => changeLevel(3, slugItem, slugSubItem, item.subnav.image)}>
                                    <ButtonLink text={item.label} />
                                  </button>
                                  <div className={`main-nav__subnav ${activeSubSubnav === slugSubItem ? 'is-active': ''}`}>
                                    <div className={`main-nav__item main-nav__item--level-more ${activeSubSubnav === slugSubItem ? 'is-visible': ''}`}>
                                      <DirectLink text={item.label} link={url} contentType={contentTypeContentful} />
                                    </div>

                                    <ul className="main-nav__subnav-list">
                                      {item.subnav.links.map((item, i) => {
                                        const {url, contentTypeContentful} = transformLink(item, true, false)  

                                        return ( 
                                          <li className={`main-nav__item main-nav__item--level-more ${activeSubSubnav === slugSubItem ? 'is-visible': ''}`} key={i}>
                                            <DirectLink text={item.label} link={url} contentType={contentTypeContentful} />
                                          </li>
                                        )
                                      })}   
                                    </ul>
                                  </div>
                                </>
                              : <DirectLink text={item.label} link={url} contentType={contentTypeContentful} />
                            }
                          </li>
                        ) 
                      })}
                    </ul>
                  </div>
                </>
              : <DirectLink text={item.label} link={url} contentType={contentTypeContentful} />
            }
          </li>
        ) 
      }) }
    </ul>
  )
}

export default NavLevel