import React from 'react'
import { Link } from 'gatsby'

import arrowRightIcon from '../icons/arrow-right.svg'
import downloadIcon from '../icons/download.svg'
import {cn} from "../utils/classnames";

const InnerContent = ({text, icon = null, reversed = false, disabled = false}) => {
  switch(icon) {
    case 'dl':
      icon = downloadIcon.url
      break
    case null:
      icon = null
      break
    default:
      icon = arrowRightIcon.url
  }

  return (
    <>
      {icon !== null &&
        <span className="button-arrow__icon center">
          <svg className={cn("icon-night", reversed && "transform rotate-180")} width="32" height="32" style={disabled ? { fill: '#ebeef4' } : {}}>
            <use xlinkHref={icon} />
          </svg>
        </span>
      }
      {text}
    </>
  )
}

const InternalLink = ({text, color, link, state, icon, contentType}) => {
  return (
    <>
      { contentType === 'ContentfulSimulator' || contentType === 'ContentfulContact' ?
        <a href={link} state={state} className={icon !== null ? `button-arrow button-arrow--${color}` : `button button--${color}`}>
          <InnerContent text={text} icon={icon} />
        </a>
      : <Link to={link} state={state} className={icon !== null ? `button-arrow button-arrow--${color}` : `button button--${color}`}>
          <InnerContent text={text} icon={icon} />
        </Link>
      }
    </>
  )
}

const ExternalLink = ({text, color, link, icon}) => {
  return (
    <a href={link} className={icon !== null ? `button-arrow button-arrow--${color}` : `button button--${color}`} target="_blank" rel="noopener noreferrer">
      <InnerContent text={text} icon={icon} />
    </a>
  )
}

const Button = ({text, color, action, icon, type, reversed = false, disabled = false}) => {
  return (
    <button
        type={type}
        className={cn(
            icon !== null ? `button-arrow button-arrow--${color}` : `button button--${color}`,
            disabled && 'cursor-default'
        )}
        style={disabled ? {
          backgroundColor: '#ebeef4'
        } : {}}
        onClick={() => {action()}}
        disabled={disabled}
    >
      <InnerContent text={text} icon={icon} reversed={reversed} disabled={disabled} />
    </button>
  )
}

const buttonArrow = ({text, link, color, external, action, state, icon = 'arrow', contentType = false, type, reversed = false, disabled = false}) => {
  let button

  const http = new RegExp('^(http|https)://', 'i')
  const assets = new RegExp('^//assets.ctfassets', 'i')
  const assets2 = new RegExp('^//downloads.ctfassets', 'i')

  if(link && (link.match(http) || link.match(assets) || link.match(assets2))) {
    external = true
  }

  if (link) {
    if (external) {
      button = <ExternalLink text={text} color={color} link={link} icon={icon} />
    } else {
      button = <InternalLink text={text} color={color} link={link} state={state} icon={icon} contentType={contentType} />
    }
  } else {
    button = <Button type={type} text={text} color={color} action={action} icon={icon} reversed={reversed} disabled={disabled} />
  }
  
  return (
    <>
    {button}
    </>
  )
}

export default buttonArrow