import React from 'react'

const Image = ({classes, width = false, height = false, image, center = false, sizes = false, quality = 90, maxWidth = false, isBg = false, isTop = false}) => {
  image = image.fields ? image.fields : image
  const url = (image.file.fields) ? image.file.fields.file.url : image.file.file ? image.file.file.url : image.file.url
  const crop = (center) ? 'center' : 'faces'

  const createUrls = (width, height, url, crop) => {
    let srcset = `${url}?fit=fill&f=${crop}&w=${width}${ height ? `&h=${height}`: ''}${ isTop ? '&fm=jpg&fl=progressive': ''}, ${url}?fit=fill&f=${crop}&w=${width * 2}${ height ? `&h=${height * 2}`: ''}${ isTop ? '&fm=jpg&fl=progressive': ''} 2x`
    let srcsetWebp = `${url}?fit=fill&f=${crop}&w=${width}${ height ? `&h=${height}`: ''}&fm=webp&q=${quality}, ${url}?fit=fill&f=${crop}&w=${width * 2}${ height ? `&h=${height * 2}`: ''}&fm=webp&q=${quality} 2x` 
    
    return {srcset, srcsetWebp}
  }

  height = (maxWidth) ? false : height
  let src = `${url}?fit=fill&f=${crop}&w=${width}${ height ? `&h=${height}`: ''}${ isTop ? '&fm=jpg&fl=progressive': ''}`
  let {srcset, srcsetWebp} = createUrls(width, height, url, crop)

  if (width === false && height === false) {
    src = `${url}?fit=fill&f=${crop}`
    srcset = `${url}?fit=fill&f=${crop}, ${url}?fit=fill&f=${crop} 2x`
    srcsetWebp = `${url}?fit=fill&f=${crop}, ${url}?fit=fill&f=${crop}&fm=webp&q=${quality} 2x`
  }

  return (
    <picture className={isBg ? 'object-fit-image-wrap' : ''}>
      { sizes && Object.keys(sizes).map((key, index) => {
        let {srcset, srcsetWebp} = createUrls(sizes[key][0], (sizes[key].length === 1) ? false : sizes[key][1], url, crop)

        return (
          <React.Fragment key={index}>
            { !isTop &&
            <source media={`(max-width: ${key}px)`} srcSet={srcsetWebp} type="image/webp" />
            }
            <source media={`(max-width: ${key}px)`} srcSet={srcset} type="image/jpg" />
          </React.Fragment>
        )
      })
      }
      { !isTop &&
        <source srcSet={srcsetWebp} type="image/webp" />
      }
      <source srcSet={srcset} type="image/jpg" />

      { isTop ?
        <img 
          className={`${classes ? classes : ''} ${isBg ? 'object-fit-image' : ''}`}
          src={src}
          srcSet={srcset}
          alt={image.altImage} />
      : <img 
          className={`${classes ? classes : ''} ${isBg ? 'object-fit-image' : ''} lazyload`}
          data-src={src}
          data-srcset={srcset}
          alt={image.altImage} />
      }
    </picture>
  )
}

export default Image