import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { connectStateResults } from 'react-instantsearch-dom'
import { useSelector, useDispatch } from 'react-redux'

import scrollManager from '../../utils/scrollManager'

import trads from '../../intl/trads.json'

import ButtonArrow from '../buttonArrow'
import SearchPanelHits from './searchPanelHits'

import semiroundShape from '../../icons/semiround-shape.svg'
import roundShape from '../../icons/round-shape.svg'
import hexagonShape from '../../icons/hexagon-shape.svg'

import {setCurrentSearchQuery, setHasSearchPanelOpen} from '../../store/actions'

const StateResults = ({ searchResults, searchState}) => {
  const lang = useSelector(state => state.lang)
  const dispatch = useDispatch()
  const hitsLength = (searchResults !== null) ? searchResults.hits.length : 0

  useEffect(() => {
    if (searchState.query !== undefined) {
      dispatch(setCurrentSearchQuery(searchState.query))
    }
  }, [searchState.query, dispatch])

  return (
    <>
      {/* only display something if query has 3 or more characters */}
      { searchState.query !== undefined && searchState.query.length >= 3 &&
      <div className="relative search-panel__wrapper-bottom" >
        {/* deco can't be put in bottoms otherwise won't work with overflow auto */}
        <div className="search-panel__decos">
          <div className="fluid-grid relative">
            <svg className="absolute icon-turquoise search-panel__semiround" width="75" height="89">
              <use xlinkHref={semiroundShape.url} />
            </svg>
            <svg className="absolute icon-night search-panel__round" width="40" height="40">
              <use xlinkHref={roundShape.url} />
            </svg>
            <svg className="absolute icon-gradient-sienne search-panel__hexagon" width="72" height="72">
              <use xlinkHref={hexagonShape.url} />
            </svg>
          </div>
        </div>

        {/* bottom content */}
        <div className="bg-white md:pt-8 pb-10 md:pb-20 search-panel__bottom">
          <div className="fluid-grid relative">
            { hitsLength === 0 &&
              <>
                <p className="title-block text-turquoise text-center">{trads[lang].search.noResults}</p>
                <p className="mt-4 text-center mb-8 md:mb-10">{trads[lang].search.noResults2}</p>
              </>
            }

            <SearchPanelHits />
              
            { hitsLength > 0 &&
              <div className="mt-10 md:mt-20">
                <Link to={lang === 'fr' ? '/recherche' : '/en/search'}>
                  <ButtonArrow
                    action={() => {dispatch(setHasSearchPanelOpen(false)); scrollManager.restart()}}
                    text={`${trads[lang].search.allResults} (${searchResults.nbHits})`}
                    color="night"
                  />
                </Link>
              </div>
            }
          </div>
        </div>
      </div>
      }
    </>
  )
}

const SearchPanelResults = connectStateResults(StateResults)

export default SearchPanelResults