import React from 'react'
import { useInView } from 'react-intersection-observer'

import quoteIcon from '../../icons/quote.svg'
import ShapeImageIllu from '../shapeImageIllu'

const Testimony = ({data, isOffer = false, isWysiwyg = false}) => {
  const { ref, inView} = useInView({ threshold: 0.3, triggerOnce: true})

  let theme
  let shape = 'pill'

  data = data.fields ? data.fields : data

  const content = (data.content.content) ? data.content.content : data.content

  switch (data.themeSolutions) {
    case 'Dégradé Bleu':
      theme = 'gradient-blue'
      break
    case 'Dégradé Sienne':
      theme = 'gradient-sienne'
      break
    default:
      theme = 'gradient-blue'
  }

  if (data.imageShape) {
    shape = data.imageShape === 'Pillule' ? 'pill' : 'hexagon-rotate2'
  }

  return (
    <div ref={ref} className={`has-full-bg testimony testimony--${theme} ${inView ? 'has-anim' : ''} ${isWysiwyg ? 'wrap-wysiwyg' : ''}`}>
      <figure className="row items-center">
        <div className={`col-12 scroll-anim-el ${isOffer || isWysiwyg ? 'md-col-5 lg-col-4' : 'md-col-5 lg-col-offset-1 lg-col-5'} mb-8 md:mb-0`}>
          <ShapeImageIllu image={data.image} theme={theme} shape={shape} />
        </div>

        <figcaption className={`col-12 scroll-anim-el scroll-anim-el--2 ${isOffer || isWysiwyg ? 'md-col-7 lg-col-5' : 'md-col-7 lg-col-6 xl-col-5 xl-col-offset-1'}`}>
          <div className="relative testimony__content">
            <svg className="absolute testimony__quote-start" width="43" height="46">
              <use xlinkHref={quoteIcon.url} />
            </svg>
            <svg className="absolute testimony__quote-end" width="43" height="46">
              <use xlinkHref={quoteIcon.url} />
            </svg>
            <blockquote className="mb-6">
              <p className="paragraph-big">{content}</p>
            </blockquote>
          </div>

          <cite className="text-night font-alternate testimony__infos testimony__content">
            {data.author}
            <span className="block mt-1">{data.job}</span>
          </cite>
        </figcaption>
      </figure>
    </div>

  )
}

export default Testimony
