import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'gatsby'
import slugify from 'slugify'

import trads from '../../intl/trads.json'

import eventDate from '../../utils/eventDate'
import transformLink from '../../utils/transformLink'

import { setHasSearchPanelOpen } from '../../store/actions'

const InnerContent = ({hit, date, contentType, lang}) => {
  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' }

  const formatedDate = (hit.contentType === 'event') ? date : new Date(date).toLocaleDateString(lang, dateOptions)

  return (
    <>
      <div className="search-hit__left">
        <span className={`flex-shrink-0 card__tag card__tag--search card__tag--${contentType}`}>
          { trads[lang].search.contentTypes[hit.contentType] }
        </span>
      </div>
      <span className="search-hit__title">{ hit.title }</span>

      <div className="search-hit__right">
        { date !== null &&
          <span className="search-hit__date">{formatedDate}</span>
        }
      </div>       
    </>
  )
}

const Hit = ({hit, setSearchState = false, emptyResults = false}) => {
  const lang = useSelector(state => state.lang)
  const dispatch = useDispatch()

  let url = transformLink(hit, false, true).url
  let date = null

  switch(hit.contentType) {
    case 'news':
      date = hit.publicationDate
      break
    case 'event':
      date = eventDate(hit.startDate, hit.endDate, trads[lang].date.from, trads[lang].date.to, '', lang)
      break
    case 'doc':
      date = hit.publicationDate
      url = hit.pdf.file.url
      break
    case 'page':
      date = null
      break
    default:
      date = null
  }

  const solutionsContentTypes = ['solution', 'offer']
  let hitName = hit.contentType

  if (solutionsContentTypes.includes(hit.contentType)) {
    hitName = hit.contentType === 'solution' ? slugify(hit.solution, {remove: /[*+~.,()'"!?:@,]/g}).toLocaleLowerCase() : `offer-${slugify(hit.solution.solution, {remove: /[*+~.,()'"!?:@,]/g}).toLocaleLowerCase()}`
  }
  
  /* eslint-disable */
  return (
    <>
    { hit.contentType === 'doc' ?
      <a href={url} target='_blank' rel="noopener noreferrer" className={`search-hit search-hit--${hitName}`}><InnerContent hit={hit} contentType={hit.contentType} date={date} lang={lang} /></a>
      : <Link onClick={() => { setSearchState ? setSearchState() : ''; dispatch(setHasSearchPanelOpen(false))}} to={url} className={`search-hit search-hit--${hitName}`}><InnerContent hit={hit} contentType={hit.contentType} date={date} lang={lang} /></Link>
    } 
    </>
  )
  /* eslint-enable */
}

export default Hit