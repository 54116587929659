import React, { useState, useEffect } from 'react'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

const Table= ({datas}) => {
  const [tableDatas, setTableDatas] = useState(null)

  useEffect(() => {
    const table = documentToHtmlString(datas)
    const fakeDOMTable = document.createElement('table')
    fakeDOMTable.innerHTML = table
    
    const headings = []
    fakeDOMTable.querySelectorAll('th p').forEach(item => {
      headings.push(item.innerHTML)
    })

    fakeDOMTable.querySelectorAll('tr').forEach(row => {
      row.querySelectorAll('td').forEach((col, i) => {
        col.setAttribute('data-label', headings[i])
      })
    })

    setTableDatas(fakeDOMTable.innerHTML)
  }, [datas])

  return (
    <div className='md:overflow-auto custom-table-wrapper' dangerouslySetInnerHTML={{__html: `<table class="custom-table">${tableDatas}</table>`}}></div>
  )
}

export default Table
