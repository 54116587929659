import React from 'react'
import Wysiwyg from './wysiwyg'

import transformLink from '../utils/transformLink'

import LinkIcon from './linkIcon'

import profileIcon from '../icons/contact-profile.svg'
import phoneIcon from '../icons/contact-phone.svg'
import linkIcon from '../icons/contact-link.svg'
import docIcon from '../icons/contact-doc.svg'
import hexagonShape from '../icons/hexagon-shape.svg'

const ContactItem = ({data, type, color = 'night', pos = null}) => {
  const item = (data.fields) ? data.fields : data
  const link = item.link ? (item.link.fields) ? item.link.fields : item.link : null
  const { url, external, mailto, tel, contentTypeContentful } = transformLink(link, true, false)
  let content = null

  if (item.content !== null && item.content !== undefined ) {
    content = item.content
  }

  let icon = ''
  let iconWidth = 32

  if (type === 'popin') {
    iconWidth = 40
  }

  switch (data.icon) {
    case 'phone':
      icon = phoneIcon.url
      break
    case 'profile':
      icon = profileIcon.url
      break
    case 'doc':
      icon = docIcon.url
      break
    case 'link':
      icon = linkIcon.url
      break
    default:
      icon = profileIcon.url
  }

  return (
    <div className="flex">
      <span className={`${type === 'popin' ? `contact-card__icon-popin ${pos % 2 === 0 ? 'contact-card__icon-popin--even' : ''}` : 'contact-card__icon'} center flex-shrink-0 mr-4`}>
        { type === 'popin' &&
          <svg className="absolute icon-night" width="64" height="64">
            <use xlinkHref={hexagonShape.url} />
          </svg>
        }
        <svg className={`icon-${color}`} width={iconWidth} height={iconWidth}>
          <use xlinkHref={icon} />
        </svg>
      </span>

      <div>
        <span className={`${type === 'popin' ? 'font-medium' : 'font-semibold'} leading-medium ${type === 'tab' || type === 'popin' ? 'text-night' : ''}`}>{item.title}</span>

        { content !== null &&
          <Wysiwyg classes={`paragraph--no-margin ${type === 'tab' ? 'text-night' : type === 'popin' ? 'text-white font-semibold' : ''}`} document={content} />
        }

        { link &&
          <LinkIcon 
            text={link.label}
            link={url}
            small="true"
            color={color}
            className="mt-2 link-underline"
            external={external}
            mailto={mailto}
            tel={tel}
            contentType={contentTypeContentful} />
        }
      </div>
    </div>
  )
}

export default ContactItem