export const changeLang = (lang) => {
    return ({
        type: 'CHANGE_LANG',
        payload: lang
    })
}

export const setCurrentSearchQuery = (query) => {
    return ({
        type: 'SET_CURRENT_SEARCH_QUERY',
        payload: query
    })
}

export const setCurrentSearchFilters = (query) => {
    return ({
        type: 'SET_CURRENT_SEARCH_FILTERS',
        payload: query
    })
}

export const setCurrentMainTag = (query) => {
    return ({
        type: 'SET_CURRENT_MAIN_TAG',
        payload: query
    })
}

export const setCurrentprofile = (query) => {
    return ({
        type: 'SET_CURRENT_PROFILE_TAG',
        payload: query
    })
}

export const setLaboEmptyOther = (query) => {
    return ({
        type: 'SET_LABO_EMPTY_OTHER',
        payload: query
    })
}

export const setFiltersChange = (query) => {
    return ({
        type: 'SET_FILTERS_CHANGE',
        payload: query
    })
}

export const setFiltersThemesNumber = (query) => {
    return ({
        type: 'SET_FILTERS_THEMES_NUMBER',
        payload: query
    })
}

export const setFiltersProfilesNumber = (query) => {
    return ({
        type: 'SET_FILTERS_PROFILES_NUMBER',
        payload: query
    })
}

export const setFiltersYearNumber = (query) => {
    return ({
        type: 'SET_FILTERS_YEAR_NUMBER',
        payload: query
    })
}

export const setFiltersTypeNumber = (query) => {
    return ({
        type: 'SET_FILTERS_TYPE_NUMBER',
        payload: query
    })
}

export const setFiltersHolderNumber = (query) => {
    return ({
        type: 'SET_FILTERS_HOLDER_NUMBER',
        payload: query
    })
}

export const setHasFixedProfileNav= (query) => {
    return ({
        type: 'SET_HAS_FIXED_PROFILE_NAV',
        payload: query
    })
}

export const setHasSearchPanelOpen= (query) => {
    return ({
        type: 'SET_HAS_SEARCH_PANEL_OPEN',
        payload: query
    })
}

export const setHasMainNavOpen= (query) => {
    return ({
        type: 'SET_HAS_MAIN_NAV_OPEN',
        payload: query
    })
}

export const saveSearchState= (query) => {
    return ({
        type: 'SAVE_SEARCH_STATE',
        payload: query
    })
}

export const changeNavImage= (query) => {
    return ({
        type: 'CHANGE_NAV_IMAGE',
        payload: query
    })
}